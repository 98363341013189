export const icons = [
{
  idx: 0,
  name: 'Start',
  icon: 'home',
  type: 'action',
  action: 'STARTMENU'
},
{
  idx: 1,
  name: 'Search',
  icon: 'search',
  type: 'action',
  action: 'SEARCHMENU'
},
{
  idx: 2,
  name: 'Widget',
  icon: 'widget',
  type: 'action',
  //action: 'WIDGETS'
},
{
  idx: 3,
  name: 'Settings',
  icon: 'settings',
  type: 'app'
},
{
  idx: 4,
  name: 'File Explorer',
  icon: 'explorer',
  type: 'app',
  //action: 'EXPLORER'
},
{
  idx: 5,
  name: 'Edge',
  icon: 'edge',
  type: 'app'
  //action: 'MSEDGE'
},
{
  idx: 6,
  name: 'Store',
  icon: 'store',
  type: 'app',
  //action: 'WNSTORE'
},
{
  idx: 7,
  name: 'Recycle Bin',
  icon: 'bin0',
  type: 'app'
},
{
  idx: 8,
  name: 'My Computer',
  icon: 'win/userDir',
  type: 'short'
}, {
  idx: 9,
  name: 'Alarms',
  icon: 'alarm',
  type: 'app'
},
{
  idx: 10,
  name: 'Calculator',
  icon: 'calculator',
  type: 'app',
  action: 'CALCUAPP'
},
{
  idx: 11,
  name: 'Calendar',
  icon: 'calendar',
  type: 'app'
},
{
  idx: 12,
  name: 'Camera',
  icon: 'camera',
  type: 'app'
},
{
  idx: 13,
  name: 'VS Code',
  icon: 'code',
  type: 'app',
  action: 'VSCODE'
},
{
  idx: 14,
  name: 'Excel',
  icon: 'excel',
  type: 'app'
},
{
  idx: 15,
  name: 'Your Phone',
  icon: 'yphone',
  type: 'app'
},
{
  idx: 16,
  name: 'Feedback',
  icon: 'feedback',
  type: 'app'
},
{
  idx: 17,
  name: 'Get Started',
  icon: 'getstarted',
  type: 'app'
},
{
  idx: 18,
  name: 'Groove Music',
  icon: 'groove',
  type: 'app'
},
{
  idx: 19,
  name: 'Help',
  icon: 'help',
  type: 'app'
},
{
  idx: 20,
  name: 'Yammer',
  icon: 'yammer',
  type: 'app'
},
{
  idx: 21,
  name: 'Mail',
  icon: 'mail',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'mailto:'
},
{
  idx: 22,
  name: 'Maps',
  icon: 'maps',
  type: 'app'
},
{
  idx: 23,
  name: 'Movies',
  icon: 'movies',
  type: 'app'
},
{
  idx: 24,
  name: 'Xbox',
  icon: 'xbox',
  type: 'app'
},
{
  idx: 25,
  name: 'MsOffice',
  icon: 'msoffice',
  type: 'app'
},
{
  idx: 26,
  name: 'Narrator',
  icon: 'narrator',
  type: 'app'
},
{
  idx: 27,
  name: 'News',
  icon: 'news',
  type: 'app'
},
{
  idx: 28,
  name: 'Notepad',
  icon: 'notepad',
  type: 'app',
  action: 'NOTEPAD'
},
{
  idx: 29,
  name: 'Sticky Notes',
  icon: 'notes',
  type: 'app'
},
{
  idx: 30,
  name: 'OneDrive',
  icon: 'oneDrive',
  type: 'app'
},
{
  idx: 31,
  name: 'OneNote',
  icon: 'onenote',
  type: 'app'
},
{
  idx: 32,
  name: 'Outlook',
  icon: 'outlook',
  type: 'app'
},
{
  idx: 33,
  name: 'Paint',
  icon: 'paint',
  type: 'app'
},
{
  idx: 34,
  name: 'People',
  icon: 'people',
  type: 'app'
},
{
  idx: 35,
  name: 'Photos',
  icon: 'photos',
  type: 'app'
},
{
  idx: 36,
  name: 'Pinterest',
  icon: 'pinterest',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://pinterest.com/'
},
{
  idx: 37,
  name: 'PowerPoint',
  icon: 'powerpoint',
  type: 'app'
},
{
  idx: 38,
  name: 'Security',
  icon: 'security',
  type: 'app'
},
{
  idx: 39,
  name: 'Spotify',
  icon: 'spotify',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://open.spotify.com/"
},
{
  idx: 40,
  name: 'Share',
  icon: 'share',
  type: 'app'
},
{
  idx: 41,
  name: 'Skype',
  icon: 'skype',
  type: 'app'
},
{
  idx: 42,
  name: 'Snipping Tool',
  icon: 'snip',
  type: 'app'
},
{
  idx: 43,
  name: 'Soltaire',
  icon: 'soltaire',
  type: 'app'
},
{
  idx: 44,
  name: 'Twitter',
  icon: 'twitter',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://twitter.com/teachjing"
},
{
  idx: 45,
  name: 'Teams',
  icon: 'teams',
  type: 'app'
},
{
  idx: 46,
  name: 'Terminal',
  icon: 'terminal',
  type: 'app',
  action: 'TERMINAL',
  payload: 'full'
},
{
  idx: 47,
  name: 'Tips',
  icon: 'tips',
  type: 'app'
},
{
  idx: 48,
  name: 'To Do',
  icon: 'todo',
  type: 'app'
},
{
  idx: 49,
  name: 'Voice Recorder',
  icon: 'voice',
  type: 'app'
},
{
  idx: 50,
  name: 'Weather',
  icon: 'weather',
  type: 'app'
},
{
  idx: 51,
  name: 'Word',
  icon: 'winWord',
  type: 'app'
},
{
  idx: 52,
  name: 'White Board',
  icon: 'board',
  type: 'app',
  action: 'WHITEBOARD'
},
{
  idx: 53,
  name: 'Cortana',
  icon: 'cortana',
  type: 'app'
},
{
  idx: 54,
  name: 'Github Links',
  icon: 'github',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://teachjing.github.io/links/github/"
},
{
  idx: 55,
  name: 'Unescape',
  icon: 'unescape',
  type: 'action',
  action: 'EXTERNAL'
},
{
  idx: 56,
  name: 'Discord',
  icon: 'discord',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://discord.gg/'
},
{
  idx: 57,
  name: 'ADX KQL Playground',
  icon: 'adx',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://dataexplorer.azure.com/clusters/help/databases/Samples?query=H4sIAAAAAAAAA03KSw5AMBRG4XmT7uFPuoCaWIIVsIHi4kof1CWRWLyaGZ58x1oYg47csKwc5xJaWYuWw+YJ+0n5RopoJeXQXBTlgLi+kIsjJvZCGZmO03+QUFda/V6tHngOLAVe1oFPH2oAAAA='
},
{
  idx: 58,
  name: 'Subscribe to TeachJing Youtube',
  icon: 'teachjing_wb',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://youtube.com/c/teachjing?sub_confirmation=1'
},
{
  idx: 59,
  name: 'Azure Sentinel Github',
  icon: 'azuresentinel',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://github.dev/Azure/Azure-Sentinel/README.md"
},
{
  idx: 60,
  name: 'Microsoft Defender Github',
  icon: 'msdefender',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://github.dev/microsoft/Microsoft-365-Defender-Hunting-Queries/README.md"
},
{
  idx: 61,
  name: 'MS Graph API Explorer',
  icon: 'msgraph',
  type: 'app',
  action: 'EXTERNAL',
  payload: "https://developer.microsoft.com/en-us/graph/graph-explorer"
},
{
  idx: 62,
  name: 'Log Analytics KQL Playground',
  icon: 'ms_loganalytics',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://portal.azure.com#@e4194ef7-b5d7-4db1-b7e9-a73af406f276/blade/Microsoft_Azure_Monitoring_Logs/DemoLogsBlade/resourceId/%2FDemo/source/LogsBlade.AnalyticsShareLinkToQuery/q/H4sIAAAAAAAAA1WLywmAMBBE76liwALixQKswTQQZTUriZ%252FNiggWb%252FQmzOHNY8ZaVBXgyA9h5mVCqcZauMAZJR6Z0xYJ%252B0FyQYNXTKQFCCNLVjQ1ZD0zRlnTpzsaDmG92khShr6PZH7O3Iic%252BL0%252B2re0nX8AAAA%253D/timespan/P7D'
},
{
  idx: 63,
  name: 'CyberChef',
  icon: 'cyberchef',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://gchq.github.io/CyberChef/'
},
{
  idx: 64,
  name: 'Adaptive Card Designer',
  icon: 'adaptivecards',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://adaptivecards.io/designer/'
},
{
  idx: 65,
  name: 'Defender Testground',
  icon: 'defender_endpoint',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://demo.wd.microsoft.com/'
},
{
  idx: 66,
  name: 'Jupyter Notebooks',
  icon: 'jupyter',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://mybinder.org/v2/gh/jingsta/jupyter-playground/master?urlpath=lab'
},
{
  idx: 67,
  name: 'Azure Resource Explorer',
  icon: 'azureresourceexplorer',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://resources.azure.com/'
},
{
  idx: 68,
  name: 'Azure Cloud Shell',
  icon: 'azurecloudshell',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://ms.portal.azure.com/#cloudshell/'
},
{
  idx: 69,
  name: 'My Links',
  icon: 'links',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://teachjing.github.io/links'
},
{
  idx: 70,
  name: 'Power BI Playground',
  icon: 'power-bi',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://playground.powerbi.com/dev-sandbox'
},
{
  idx: 71,
  name: 'PowerBI Report Samples',
  icon: 'power-bi-reports',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://teachjing.github.io/links/powerbi/'
},
{
  idx: 72,
  name: 'VSCode',
  icon: 'vscode',
  type: 'app',
  action: 'EXTERNAL',
  payload: 'https://vscode.dev/'
},
];
/* Need to make icons for these

Azure Sentinel Demo Playground - https://aka.ms/lademo
Azure Resource Explorer - https://resources.azure.com/
Azure QuickStart Templates - https://azure.microsoft.com/en-us/resources/templates/
Adaptive Card Designer - https://adaptivecards.io/designer/
Fluent UI React Components - https://developer.microsoft.com/en-us/fluentui#/controls/web
Microsoft Code Samples - https://docs.microsoft.com/en-us/samples/browse/
PowerBI Embedded Analytics Playground - https://playground.powerbi.com/home
https://playground.powerbi.com/dev-sandbox
https://msportals.io/ - https://github.com/adamfowlerit/msportals.io
Microsoft Network Connectivity Test - https://connectivity.office.com/
https://amdesigner.azurewebsites.net/


*/