const defState = {
  lays: [
    [{
      dim: {
        width: "50%"
      },
      br: 14
    }, {
      dim: {
        width: "50%",
        left: "50%"
      },
      br: 15
    }],
    [{
      dim: {
        width: "66%"
      },
      br: 14
    }, {
      dim: {
        width: "34%",
        left: "66%"
      },
      br: 15
    }],
    [{
      dim: {
        width: "33%"
      },
      br: 14
    }, {
      dim: {
        width: "34%",
        left: "33%"
      },
      br: 1
    }, {
      dim: {
        width: "33%",
        left: "67%"
      },
      br: 15
    }],
    [{
      dim: {
        width: "50%"
      },
      br: 14
    }, {
      dim: {
        width: "50%",
        height: "50%",
        left: "50%"
      },
      br: 3
    }, {
      dim: {
        width: "50%",
        height: "50%",
        top: "50%",
        left: "50%"
      },
      br: 5
    }],
    [{
      dim: {
        width: "50%",
        height: "50%"
      },
      br: 2
    }, {
      dim: {
        width: "50%",
        height: "50%",
        left: "50%"
      },
      br: 3
    }, {
      dim: {
        width: "50%",
        height: "50%",
        top: "50%"
      },
      br: 7
    }, {
      dim: {
        width: "50%",
        height: "50%",
        top: "50%",
        left: "50%"
      },
      br: 5
    }],
    [{
      dim: {
        width: "25%"
      },
      br: 14
    }, {
      dim: {
        width: "50%",
        left: "25%"
      },
      br: 1
    }, {
      dim: {
        width: "25%",
        left: "75%"
      },
      br: 15
    }]
  ]
};

const globalReducer = (state = defState, action) => {
  return state;
}

export default globalReducer;
