import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Icon, Image, ToolBar} from '../../../utils/general';

export const AboutWin = ()=>{
  const {abOpen} = useSelector(state=> state.desktop);
  const [open, setOpen] = useState(()=>{ return false }
    /*if(localStorage.getItem('closeAbout')=="true"){
      return false;
    }else{
      return true;
    }*/
  );
  const dispatch = useDispatch();

  localStorage.setItem('closeAbout',true);

  const action = ()=>{
    setOpen(false);
    localStorage.setItem('closeAbout',true);
    dispatch({type: "DESKABOUT", payload: false});
  }
  //Test
  return open || abOpen?(
    <div className="aboutApp floatTab dpShad">
      <div className="py-1 px-2 bg-gray-100 text-xss">
        <div className="">About TeachJing</div>
      </div>
      <div className="windowScreen flex flex-col" data-dock="true">
        <div className="restWindow h-full flex-grow flex flex-col items-center p-4">
          {/*<Image src="windows11" free/>*/}
          <div className="w-88 h-px bg-gray-400 my-4">
          </div>
          <div className="abCont">
            <br/>
            <br/>
            <div>
            Windows 11 themed security desktop with tools, links, and sites that I like.
            </div>
            <br/>
            <br/>
            <br/>
            <div className="mt-1">
              <a href="https://github.com/blueedgetechno/windows11" target="_blank">Shoutouts to Blueedgetechno for creating this Windows 11 desktop using React!</a>
            </div><br/>
            <div className="okbtn">
              <div className="bg-gray-100" onClick={action}>Ok</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ):null;
}
