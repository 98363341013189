import Webamp from "webamp"; // eslint-disable-line import/no-unresolved
// This import gives us a URL to llama.mp3
import llama from "./llama.mp3";
import teachJingSong from "./Teachjing-Cyber_Anthem.mp3";
import teachJingInst from "./Teachjing - Cyber Anthem (instrumental).mp3";
// Import skins
import bento from "./skins/Bento_Classified.wsz"
import cyber from "./skins/Cyberdome.wsz"
import winamp3 from "./skins/Winamp3_Classified.wsz"
import fallout from "./skins/Fallout_Pipboy.wsz"

if (!Webamp.browserIsSupported()) {
  alert("Oh no! Webamp does not work in this browser!");
  throw new Error("What's the point of anything?");
}

const webamp = new Webamp({
  initialTracks: [
    {
      metaData: {
          artist: "TeachJing",
          title: "Cyber Anthem"
      },
      url: teachJingSong,
      duration: 3.65
    },
    {
      metaData: {
          artist: "TeachJing",
          title: "Cyber Anthem (Instrumental)"
      },
      url: teachJingInst,
      duration: 3.65
    },
    {
      metaData: {
        artist: "DJ Mike Llama",
        title: "Llama Whippin' Intro"
      },
      url: llama,
      duration: 5.322286
    }
  ],
  availableSkins: [
    { url: cyber, name: "Cyber" },
    { url: bento, name: "Bento" },
    { url: fallout, name: "Fallout Pipboy" },
    { url: winamp3, name: "Classified" },
  ]
});

// Here we go! Let's show Webamp.
webamp.renderWhenReady(document.getElementById("winamp"));
